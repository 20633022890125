@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Ranade';
  src: url('/public/assets/Ranade-Variable.ttf') format('ttf');
}

@font-face {
  font-family: 'LatoLatin';
  src: url('/public/assets/LatoLatin-Regular.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.cdnfonts.com/css/futura?v=2');



*,
body {
  margin: 0;
  font-family: 'LatoLatin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background-color: hsl(231, 16%, 92%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root,
body,
html {
  /* Prevent overscroll on chrome (not supported on safari) */
  overscroll-behavior-y: none;
}

html, body, #root, #root > div {
  height: 100%;
}

body {
  background: linear-gradient(0deg,#4a5568,#3f8892 42%,rgba(70,107,120,.995));
  color: #fff;
}

.practiceMode {
  background: linear-gradient(0deg,#8e6389,#4971a6 42%,rgb(201 120 120));
  color: #fff;
}

.subTitleMode {
  font-size: 8px;
  line-height: 0;
  display: block;
}

.h-fill {
  height: 120vh;
  max-height: -webkit-fill-available;
}

.font-righteous {
  font-family: 'Righteous', cursive;
}

.letterTile {
  background-color:rgba(32,25,25,.6);
  border-radius: 6px;
  color: white;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

.keyboardButton,
.letterTile
{
  font-size: 25px;
  font-weight: bold;
}

.keyboardButton {
  width: 10vw;
  height: 9vh;
 /* max-height: 60px; */
}

.TomhasButton {
  width: 20vw;
  min-width: 100px;
}

.letterTile {
  height: 15vw;
  width: 18%;
}

.appContainer {
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

.shadow {
  -moz-box-shadow:    inset 0 0 10px #666666;
  -webkit-box-shadow: inset 0 0 10px #666666;
  box-shadow:         inset 0 0 10px #666666;
}

body {
  background-color: antiquewhite;
}

.dark {
  color: white;
}

a {
  text-decoration: rgb(12, 12, 11) underline;
}

.dark a {
  text-decoration: antiquewhite underline;
}

.keyboardContainer,
.gameStats {
  width: 100%;
 /* height: 140px; */
  margin-bottom: .5rem;
}

.gameStats {
  display: flex;
  padding: 12px;
}

.stat {
  width: 50%;
  text-align: center;
}

.endGameButton-Litreach {
  position: relative;
}

.endGameButton-LitreachLogo {
  width: 20px;
  display: inline;
  margin-left: 0.5rem;
}

.endGameButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2em;
}

.endGameButton {
  padding: .375rem .75rem;
  border: 1px solid transparent;
  border-color: #9a9aa0;
  border-radius: 1.5rem;
  width: inherit;
  font-weight: 700;
  font-size: .8rem;
}

.shareButton {
  background-color: grey;
  color: white;
  margin-right: 16px;
}

.shareButton:disabled {
  background-color: #80808045;
  color: #80808045;
}

.number {
  font-size: xx-large;
}

.keyboardRow {
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
  justify-content: center;
}

.keyboardButton {
  width: 30px;
  max-width: calc(10%);
  font-family: inherit;
  font-weight: normal;
  font-size: x-large;
  border: 0;
  padding: 0;
  margin: 0 3px 0 0;
  height: 9vh;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: rgba(32,25,25,.6);
  color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0.3);
  text-transform: lowercase;
}

.rightLetterWrongPlace {
  background-color: #ba7a39;
  border: 2px white;
  border-style: dashed;
  transition-property: background-color, border;
  transition-duration: .5s;
}

.rightLetterRightPlace {
  background-color: #6aaa64;
  border: 2px solid white;
  transition-property: background-color, border;
  transition-duration: .5s;
}

.delay0 {
  transition-delay: .5s;
}

.delay1 {
  transition-delay: 1s;
}

.delay2 {
  transition-delay: 1.5s;
}

.delay3 {
  transition-delay: 2s;
}

.delay4 {
  transition-delay: 2.5s;
}

@keyframes rightLetterRightPlace {
  0% { background-color: #6aaa64;
          border: 2px solid white; }
}

@keyframes rightLetterWrongPlace {
  0% {
    background-color: #ba7a39;
    border: 2px dashed white;
  }
}

.wrongLetter {
  /* PATTERN MAKER https://www.magicpattern.design/tools/css-backgrounds */
  background-color: #9a9aa0;
  opacity: 0.8;
  transition-property: background-color;
  transition-duration: .5s;
 /* background: repeating-linear-gradient( -45deg, #838383, #838383   2px, #9a9aa0 2px, #9a9aa0 10px ); */
}

.invalidWordContainer {
  height: 35px;
  text-align: center;
  max-width: 344px;
}


.submittedInvalidWord {
  border-color: #532222;
  background-color: #715151;
  animation: shake-animation 4.72s ease;
  transform-origin: 50% 50%;
}

.radio-btn {
  display: flex;
  align-items: center;
}

.radio-btn input {
  margin-right: .5rem;
}

@keyframes flip-animation {
  
}

@keyframes shake-animation {
   0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(10px, 0); }
	20% { -webkit-transform: translate(-10px, 0); }
	10% { -webkit-transform: translate(8px, 0); }
	40% { -webkit-transform: translate(-8px, 0); }
	50% { -webkit-transform: translate(4px, 0); }
	60% { -webkit-transform: translate(-4px, 0); }
	70% { -webkit-transform: translate(4px, 0); }
	80% { -webkit-transform: translate(-2px, 0); }
	0% { -webkit-transform: translate(2px, 0); }
	100% { -webkit-transform: translate(0, 0); }
}

.icons {
  height: 2rem;
  width: 2rem;
}

.AnswerHistory-Heading,
hr {
  color: #dcdcdcc4;
}

.AnswersHistory-List {
  width: 100%;
  
}

.AnswersHistory-List th {
  text-align: left;
  color: #dcdcdcc4;
}

.AnswersHistory-List th,
.AnswersHistory-List td {
  padding: .5rem;
}

.BriAnFhocail {
  justify-content: center;
  display: flex;
  color: #dcdcdcc4;
  text-decoration-color: #dcdcdcc4;
}

.appHeader {
  display: flex;
  justify-content: space-between;
  color: white;
  height: 72px;
  align-items: baseline;
}

.statisticIcon,
.InfoIcon {
  margin-right: 1rem;
}

.HistoryIcon {
  color: white;
}

.siteTitle {
  font-family: 'Futura', sans-serif;
  color: white;
  font-size: 3rem;
  font-weight: 700;
}

.gameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: scroll;
}

.gameBoard {
  background-color: rgba(32,25,25,.2);
  width: 90vw;
  max-width: 344px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
}

/* modals styles */

.closeButton,
.gitHubIcon {
  width: 1.5em;
  float: right;
}

.modalTitle {
  font-size: xx-large;
  text-align: center;
  margin-bottom: 1em;
}

.downloadAppContainer {
  margin-bottom: 2em;
  margin-top: 2em;
}

.downloadTitle {
  font-size: large;
  margin-bottom: 1em;
}

.downloadBenefits {
  margin-left: 1em;
}

.infoModal-Container .endGameButton, 
.downloadAppContainer .endGameButton {
  width: 100%;
  margin-top: 2em;
}

.infoModal-Rule {
  margin-bottom: 1em;
}

.gitHubLink {
  margin-top: 3em;
  font-size: .8em;
}

.endGameMode-Heading {
  margin-bottom: 0;
}

.endGameMode-Heading,
.endGameMode-Label,
.endGameModal-ModeMessage {
  color: #dcdcdcc4;
}

.EndGameModal-Heading {
  display: none;
}

.endGameMode-StatisticNumber {
  font-size: 30px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  font-variant-numeric: proportional-nums;
  line-height: 1em;
 }

 .endGameMode-statisticContainer {
   flex: 1;
   min-width: 50%;
   margin-bottom: 1rem;
 }

 .endGameModal-Header {
   display: flex;
   justify-content: space-between;
   align-items: baseline;
 }

 .endGameModal-Header h1 {
   padding-left: 1rem;
 }

 .endGameModal-Message {
   text-align: center;
 }

 .endGameModal-Statistics, 
 .endGameModal-Message,
 .endGameModal-Countdown,
 .endGameModal-ModeMessage,
 .endGameMode-GameModePicker,
 .endGameModal-Share {
   margin-bottom: 2rem;
 }

 .endGameMode-GameModePicker {
   padding-bottom: 2rem;
 }

 .radio-btn {
   padding-bottom: 1rem;
 }

 .endGameModal-Statistics {
   display: flex;
   flex-flow: wrap;
 }

 .endGameMode-statisticContainer .label {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.EndGameModal-Icon {
  margin-right: .5em;
  margin-left: 0.5rem;
}

h1 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 10px;
}

.CountdownTimer {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  font-variant-numeric: proportional-nums;
}

.Toastify__toast-theme--light.infoToast {
  background: #4b7d8f;
  color: #ffffff;
}

.SettingModal-paragraph {
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}

#guess-distribution {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.graph-container {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
}

.graph-container .graph {
  width: 100%;
  height: 100%;
  padding-left: 4px;
}

.graph-container .graph .graph-bar {
  height: 100%;
  width: 0%;
  position: relative;
  background-color: grey;
  display: flex;
  justify-content: flex-end;
}

.num-guesses {
  align-self: center;
  margin-right: .2em;
}

.guess {
  width: 1em;
}

.percentage {
  font-size: .5em
}



/*
***************************************************************
CARD ANIMATIONS
****************************************************************
*/

.is-flipped {
  /*transform: rotateY(180deg); */
  animation: column;
  animation-duration: 1s;
  animation-delay: 5s;
}

.is-flipped > span {
  animation: column;
  /* transform: rotateY(180deg); */
}

.is-flippedColumn0 {
  animation: column;
  animation-duration: .5s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
}

.is-flippedColumn0 > span {
  animation: column;
  animation-duration: .5s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
}

.is-flippedColumn1 {
  animation: column;
  animation-duration: .5s;
  animation-delay: .5s;
  animation-timing-function: ease-in;
}

.is-flippedColumn1 > span{
  animation: column;
  animation-duration: .5s;
  animation-delay: .5s;
  animation-timing-function: ease-in;
}

.is-flippedColumn2 {
  animation: column;
  animation-duration: .5s;
  animation-delay: 1s;
  animation-timing-function: ease-in;
}

.is-flippedColumn2 > span {
  animation: column;
  animation-duration: .5s;
  animation-delay: 1s;
  animation-timing-function: ease-in;
}

.is-flippedColumn3 {
  animation: column;
  animation-duration: .5s;
  animation-delay: 1.5s;
  animation-timing-function: ease-in;
}
.is-flippedColumn3 > span {
  animation: column;
  animation-duration: .5s;
  animation-delay: 1.5s;
  animation-timing-function: ease-in;
}

.is-flippedColumn4 {
  animation: column;
  animation-duration: .5s;
  animation-delay: 2s;
  animation-timing-function: ease-in;
}

.is-flippedColumn4 > span {
  animation: column;
  animation-duration: .5s;
  animation-delay: 2s;
  animation-timing-function: ease-in;
}

.is-flippedColumn5 {
  animation: column;
  animation-duration: .5s;
  animation-delay: 2.5s;
  animation-timing-function: ease-in;
}

.is-flippedColumn5 > span {
  animation: column;
  animation-duration: .5s;
  animation-delay: 2.5s;
  animation-timing-function: ease-in;
}

@keyframes column {
  80% { transform: rotateY(90deg); }
}

/*
**********************************************************************
MEDIA QUERIES
*********************************************************************
*/

@media (min-width: 500px) {
  .letterTile {
    max-width: 60px;
    max-height: 60px;
  }

  .keyboardContainer {
    max-width: 600px;
    margin: 0 auto;
   /* height: 190px;*/
  }

  .keyboardButton {
    width: 40px;
   /* height: 48px */
  }
}


@media (max-height: 736px) {
  .letterTile {
    height: 13vw;
  }

  .appHeader {
    height: 40px;
  }

  .siteTitle {
    font-size: 2em;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)
{
   .letterTile {
     height: 10vw;
     width: 10vw;
   }

   .gameBoard {
     width: 65vw;
     padding-left: 14px;
   }
}